import React from 'react';
import '../../containers/App.css';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
// import Services from '../../components/Services'; 
// import Contact from '../../components/Contact'; 
import Footer from '../../components/Footer';

function Home() {
  return (
    <>
      <Header />
      <HeroSection />
      {/* <Services /> */}
      {/* <Contact /> */}
      <Footer />
    </>
  );
}

export default Home;