import React from 'react';
import './Services.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


function Services(props) {
  return (
    <>
    <Header />
    <div id='services' className='services'>
        
        <div className="container-services">
          <h1 className='title-servives'>Serviços</h1>
      
          <div className='desc-services'>
              <p>-  Maior base de carros do Brasil</p>
              <p>-  Alertas personalizados para encontrar seu carro assim que entrar no mercado</p>
              <p>-  Frete facilitado e reduzido para trazer o carro até você ou seu cliente</p>
              <p>-  Anuncie em todas as plataformas com um único clique</p>
              <p>-  Postagens programadas no Facebook, para manter seu anúncio sempre no topo</p>
              <p>-  Central de informações do mercado. Saiba tudo sobre as vendas que estão acontecendo no brasil inteiro</p>
              <p>-  Financiamento facilitado dentro da própria plataforma.</p>
              <p>-  Antecipe as parcelas de sua venda.</p>
              <p>-  Dashboard de consulta de dados de venda</p>
          </div>
        </div>

    </div>
    <Footer />
    </>
  );
}

export default Services;