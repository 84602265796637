import React from 'react';
import './About.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function About(props) {
  return (
    <>
    <Header />
    <div id='about' className='about'>
        
        <div className='desc-about'>
           <img src="images/about_01.jpg" alt="Sobre"></img>
        </div>
      
    </div>
    <Footer />
    </>
  );
}

export default About;