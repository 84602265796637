import React , {useState} from 'react';
import { Button } from './Button';
import './HeroSection.css';
import { Modal } from './Modal';


function HeroSection() {

  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(prev => !prev)
  }  

  return (
     <div id='herosection' className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      <h1>Aumente as vendas da sua garagem encontrando os melhores carros</h1>
      <p>Não sofra mais pesquisando por carros.</p>
      <p>Coletamos as informações de todas as plataformas e procuramos ativamente por novas oportunidades para você!</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn-outline'
          buttonSize='btn-large'
          route='/register'
        >
          COMECE AGORA
        </Button>
        <Button
          className='btns'
          buttonStyle='btn-primary'
          buttonSize='btn-large'
          route='/alertregister'
          onClick={openModal}
        >
          VEJA COMO FUNCIONA <i className='far fa-play-circle' />
          {/* <Modal showModal={showModal} setShowModal={setShowModal} /> */}
        </Button>
        
      </div>
    </div>
  );
}

export default HeroSection;