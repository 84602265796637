import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => (

  <Route
    {...rest}
    render={(props) => {
      const tokens = localStorage.getItem('tokens') !== null;
      return (tokens
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/alarm', state: { from: props.location } }} />
      );
    }}
  />
);

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { loggedIn } = authentication;

  return { loggedIn };
};

// export default connect(mapStateToProps, null)(withRouter(PrivateRoute));
export default PrivateRoute;
