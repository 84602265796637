import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Header.css';

function Header() {

    const user = localStorage.getItem('user');
    const [showUser, setShowUser] = useState(true);
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if (window.innerWidth <= 960){
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    const closeUser = () => {
        // localStorage.clear()
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
                    <i className='fas fa-car'/>Popcars 
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Início
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                Quem Somos
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                                Serviços
                            </Link>
                            {/* <AnchorLink href='#services'>Serviços</AnchorLink> */}
                        </li>
                        <li className='nav-item'>
                            <Link to='/client' className='nav-links' onClick={closeMobileMenu}>
                                Clientes
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                Contato
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/login' className='nav-links-mobile' onClick={closeMobileMenu}>
                                Login
                            </Link>
                        </li> 
                    </ul>

                    {
                        user ? 
                            <div className='nav-menu-user'>
                                <h3 className='nav-user'>{user}</h3> 
                                    <div className='btn-alert'>
                                        {button && 
                                            <Button 
                                                route='/alert'
                                                >Alertas
                                            </Button>
                                        }
                                    </div>
                                    <div>
                                        {button && 
                                            <Button 
                                                buttonStyle='btn-outline' 
                                                route='/' 
                                                onClick={closeUser}
                                                >Sair
                                            </Button>
                                        }
                                    
                                    </div>
                            </div>
                                : 
                            <div className='btn-login'>
                                {button && 
                                    <Button login={showUser}
                                        buttonStyle='btn-outline' 
                                        route='/login' 
                                        onClick={closeMobileMenu}
                                        >Login
                                    </Button>
                                }
                            </div>
                    }
                </div>
                
                <script 
                    src="//unpkg.com/vanilla-masker@1.1.1/build/vanilla-masker.min.js">
                </script>

            </nav>

        </>
    )
}

export default Header
