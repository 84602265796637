import React from 'react';
import './Client.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Client(props) {
  return (
    <>
    <Header />
    <div id='client' className='client'>
        <h1 className='title-client'>Clientes</h1>
        
        <div className='desc-client'>
            <img src="images/clientes_01.jpg" alt="Clientes"></img>
        </div>
      
    </div>
    <Footer />
    </>
  );
}

export default Client;