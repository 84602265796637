import React from 'react';
import './Contact.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Contact(props) {
  return (
    <>
    <Header />
    <div id='contact' className='contact'>
        <h1 className='title-contact'>Contato</h1>
        
        <div className='desc-contact'>
        <form>
            <input
              className='contact-input'
              name='email'
              type='email'
              placeholder='Email'
            />
            <input
              className='contact-input'
              name='phone'
              type='text'
              placeholder='Telefone'
            />
            <input
              className='contact-input'
              name='subject'
              type='text'
              placeholder='Assunto'
            />
            <textarea 
              className='contact-input'
              name='message'
              type='text'
              placeholder='Mensagem'
              rows="5" 
              cols="50"
            />
          </form>
          
          <button className="contact-btn" type="submit">Enviar</button>
        
        </div>
      
    </div>
    <Footer />
    </>
  );
}

export default Contact;