import React, {useState} from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ErrorMessage, Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import api from '../../services/api'
import './Login.css'

const Login = () => {

    const [email, setEmail] = useState('')  
    const [password, setPassword] = useState('')

    async function handleSubmit(){
        const data = {
            email: email,
            password: password
            }
        
        if(email !== '' && password !== '' ){

            try {
                
                const response = await api.post('/login', data);

                const {accessToken,refreshToken,user} = response.data

                localStorage.setItem("accessToken",accessToken)
                localStorage.setItem("refreshToken",refreshToken)
                localStorage.setItem("user",user.name)

                if(response.status === 200){
                    window.location.href='/alert'
                }

            }
            catch(error){
                    alert('Usuário ou senha incorreto!');
            }
    
        }else{
            alert('Por favor, preencha todos os dados!');
        }        

    }

    const validations = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(8).required()
    })

    return (
        <>
        <Header />
        <video className="login-background" src='/videos/video-2.mp4' autoPlay loop muted />
        {/* <img className="login-background" src="images/modal_01.jpg" alt="Clientes"></img> */}
            <div className="login-style">
                <h1 className="login-tittle">Login</h1>
                <Formik
                    initialValues={{}}
                    onSubmit={handleSubmit}
                    validationSchema={validations}
                >
                    <Form className="login">
                        <div className="login-group">
                            <Field
                                name="email"
                                className="login-field"
                                placeholder="E-mail"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {/* <ErrorMessage
                                component="span"
                                name="email"
                                className="login-error"
                            /> */}
                        </div>
                        <div className="login-group">
                            <Field
                                name="password"
                                // type="password"
                                className="login-field"
                                placeholder="Senha"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            {/* <ErrorMessage
                                component="span"
                                name="password"
                                className="login-error"
                            /> */}
                        </div>
                        <button className="login-btn" type="submit" onClick={handleSubmit}>Login</button>
                        
                    </Form>
                </Formik>
            </div>
        <Footer />    
        </>
    )
}

export default Login