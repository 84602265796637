import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../components/Routes';
import './App.css';

navigator.geolocation.getCurrentPosition(function(position){
  console.log(position);
});

const App = () => (
   <BrowserRouter>
     <main className="App">
         <Routes/>
     </main>
   </BrowserRouter>
);

export default App;
