import React, {useState , useEffect}  from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// import { Link } from 'react-router-dom';
// import * as yup from 'yup'
// import axios from 'axios'
import './Alert.css';
import api from '../../services/api'
// import { message } from 'antd';
import CurrencyFormat from 'react-currency-format';

const Alert = () => {
  
   const [alerts, setAlerts]  = useState([])
   const [loading, setLoading] = useState(true)
   
   async function getAlert(){
      const headers = {
         Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }

      const config = {
         headers
      }

      const response = await api.get('/vehicle-alerts', config);
      setAlerts(response.data)
      setLoading(false)
   }

   useEffect(getAlert, [])

   const handleSubmit = () => {
      window.location.href='/alertregister'
   }

   const editingSubmit = (id) => {
      window.location.href='/alertregister/'+id
   }

   async function deleteAlert(id){

      console.log('deletado')

      const headers = {
         Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }

      const config = {
         headers
      }

      const response = await api.delete('/vehicle-alerts/'+id, config);

      alert('Alerta deletado com sucesso!');
      window.location.href='/alert'

   }

   return (
   <>
   <Header />
      <h1 className="alert-title">Alertas Cadastrados</h1>
      <div className="alert-container">      
         {
            loading ? <h4 className='alert-loading'>Carregando...</h4> : ''
         }
         {
            alerts.map(alert => {
               return (
                  <div className='alert-model'>
                     <div className='alert-view'>
                        <h2>Alerta: <h3>{alert.name}</h3></h2>
                        <h2>Marca: <h3>{alert.brand}</h3></h2>
                        <h2>Model: <h3>{alert.model}</h3></h2>
                        <h2>Preço Mínimo: 
                           <h3><CurrencyFormat
                              thousandSeparator={true} 
                              thousandSeparator="."
                              prefix={'R$ '}
                              // type="string"
                              value={alert.minPrice}
                              className='alert-view-cur'
                           /></h3>
                        </h2>
                        <h2>Preço Máximo: 
                           <h3><CurrencyFormat
                                 thousandSeparator={true} 
                                 thousandSeparator="."
                                 prefix={'R$ '}
                                 // type="string"
                                 value={alert.maxPrice}
                                 className='alert-view-cur'
                           /></h3>
                        </h2>
                        <h2>Ano Inicial: <h3>{alert.minYear}</h3></h2>
                        <h2>Ano Final: <h3>{alert.maxYear}</h3></h2> 
                        <button className='edit-alert-btn' onClick={() => editingSubmit(alert.id)}>Editar Alerta</button>
                        <button className='delete-alert-btn' onClick={() => deleteAlert(alert.id)}>Deletar Alerta</button>
                     </div>
                  </div>
               );
            })
         }
      </div>

      <div className="new-alert">
         <button className="alert-btn" type="submit" onClick={handleSubmit}>Novo Alerta</button>
      </div>             
   <Footer />    
      </>
   )
}

export default Alert;