import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div id='footer' className='footer-container'>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Localização</h2>
            <Link to='/'>Cidade: Caxias do Sul</Link>
            <Link to='/'>Rua: Xalala do xalala</Link>
            <Link to='/'>CEP: 999999-99</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Atendimento</h2>
            <Link to='/'>E-mail: contato@popcars.com</Link>
            <Link to='/'>Telefone: (54) 3333-3333</Link>
            <Link to='/'>Redes sociais abaixo</Link>
          </div>  
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <i class='fas fa-car' />
              Popcars
            </Link>
          </div>
          <small class='website-rights'>Copyright © 2020</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              class='facebook-logo'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              class='instagram-logo'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link linkedin'
              class='linkedin-logo'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
            <Link
              class='social-icon-link youtube'
              class='youtube-logo'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              class='twitter-logo'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link whatsapp'
              class='whatsapp-logo'
              to='/'
              target='_blank'
              aria-label='Whatsapp'
            >
              <i class='fab fa-whatsapp' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;