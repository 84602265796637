import React, {useState , useEffect}  from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ErrorMessage, Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import './AlertRegister.css';
import api from '../../services/api';
import CurrencyFormat from 'react-currency-format';

const AlertRegister = (props) => {

   console.log(props.match.params.id)

   const [name, setName] = useState(props.match.params.id);
   const [brand, setBrand] = useState();
   const [model, setModel] = useState();
   const [minPrice, setMinPrice] = useState();
   const [maxPrice, setMaxPrice] = useState();
   const [minYear, setMinYear] = useState("1900");
   const [maxYear, setMaxYear] = useState("2021");
   const [maxDistance, setMaxDistance] = useState("100");
   const [brandsModels, setBrandsModels] = useState({})
   const [alerts, setAlerts]  = useState([])

   const id = window.location.pathname;

 
      // const headers = {
      //    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      // }

      // const config = {
      //    headers
      // }

      // const response = await api.get('/vehicle-alerts/'+props.match.params.id);
      // console.log('/vehicle-alerts/'+props.match.params.id)
      // setAlerts(response.data)
      // console.log(response.data)
      // setLoading(false)


   async function getCar(){
      const headers = {
         Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }

      const config = {
         headers
      }

      const response = await api.get('/brands', config);

      // Mesma forma de fazer a construção do objeto unico da lina 49
      // const brandIndexed = {}

      // response.data.forEach(brand => {
      //    brandIndexed[brand.name]= brand.models
      // })

      // console.log(brandIndexed)

      const brandIndexed = response.data.reduce((acc, brand) => {acc[brand.name]=brand.models; return acc;},{})

      setBrandsModels(brandIndexed)
      setBrand(response.data[0].name)

   }

   useEffect(getCar, [])

   async function handleSubmit(){

      const data = {
         name: name,
         brand: brand,
         model: model,
         minPrice: minPrice,
         maxPrice: maxPrice,
         minYear: minYear,
         maxYear: maxYear,
         maxDistance: maxDistance,
      }

      const headers = { 
         Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }

      const config = {
         headers
      }

      console.log(data)

      try{

         const response = await api.post('/vehicle-alerts', data, config);

         if(response.status === 200){
            alert('Alerta cadastrado com sucesso!');
            window.location.href='/alert'
         }

      }catch(error){
         alert('Erro ao cadastrar alarme!');            
      }
   }

   async function putAlert(){

      const data = {
         name: name,
         brand: brand,
         model: model,
         minPrice: minPrice,
         maxPrice: maxPrice,
         minYear: minYear,
         maxYear: maxYear,
         maxDistance: maxDistance,
      }

      const id = window.location.pathname

      const headers = {
         Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }

      const config = {
         headers
      }

      const response = await api.put('/vehicle-alerts'+id.replace('/alertregister/','/'), data, config);
      // setAlert(response.data)

      if(response.status === 200){
         window.location.href='/alert'
      }else{
         console.log('aqui')
         alert('Erro ao no registro')
      }

      if(response.status != 200){
         console.log('ali')
         alert('Erro ao no registro')
      }
      
   }

   function verificationTypeTitle(){
      if (id.length <= 15) {
         return <h1 className="alert-title">Registro de Alerta</h1>
      } else {
         return <h1 className="alert-title">Ajuste de Alerta</h1>
      }
   }

   function verificationTypeBtn(){

      if (id.length <= 15) {
         return <button className="alert-btn" type="submit" onClick={handleSubmit}>Cadastrar</button>
      } else {
         return <button className="alert-btn" type="submit" onClick={putAlert}>Ajustar</button>
      }
   }

   const validations = yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().min(8).required()
   })

   return (
      <>
      <Header />
         {verificationTypeTitle()}
            <div className="alertregister-container">      
               <Formik
                  initialValues={{}}
                  onSubmit={handleSubmit}
                  validationSchema={validations}>
                  <Form className="alert-form">
                     <div className="alert-form-one">
                        <Field
                           type="text"
                           name="name"
                           className="alert-field"
                           placeholder="Nome do alerta"
                           value={name}
                           onChange={e => setName(e.target.value)}
                        />
                           <ErrorMessage
                              component="span"
                              name="email"
                              className="login-error"
                        />
                     </div>

                     <div className="alert-form-two">
                        
                        <select 
                           id="brandCarName"
                           name="car-brand"
                           className="alert-field-select"
                           value={brand}
                           onChange={e => setBrand(e.target.value)}
                           >{Object.keys(brandsModels).map(brand => (<option>{brand}</option>))}
                        </select>
                        
                        <select 
                           name="car-model"
                           className="alert-field-select"
                           value={model}
                           onChange={e => setModel(e.target.value)}
                           >{brand && brandsModels[brand].map(model => (<option>{model}</option>))}
                        </select>
                     </div>
                     
                     <div className="alert-form-two">
                        <Field
                           name="init-value"
                           render={({ field }) => (
                              <CurrencyFormat
                                    thousandSeparator={true} 
                                    // thousandSeparator="."
                                    decimalSeparator=","
                                    // decimalSeparator={true} 
                                    prefix={'R$ '}
                                    type="text"
                                    name="init-value"
                                    className="alert-field"
                                    placeholder="Valor Inicial"
                                    value={minPrice}
                                    onValueChange={e => {setMinPrice(e.value);console.log(e)}}
                              />
                           )}
                        />
                           <Field
                              name="end-value"
                              render={({ field }) => (
                                 <CurrencyFormat
                                       thousandSeparator={true} 
                                       // thousandSeparator="."
                                       decimalSeparator=","
                                       // decimalSeparator={true}
                                       prefix={'R$ '}
                                       type="text"
                                       name="end-value"
                                       className="alert-field"
                                       placeholder="Valor Final"
                                       value={maxPrice}
                                       onValueChange={e => setMaxPrice(e.value)}
                                 />
                              )}
                           />
                        </div>  

                        <div className="alert-form-two">
                           <Field
                              type="select"
                              name="init-year"
                              className="alert-field"
                              placeholder="Ano Inicial"
                              min="1900"
                              max="2021"
                              value={minYear}
                              onChange={e => setMinYear(e.target.value)}
                           />
                           <ErrorMessage
                              component="span"
                              name="password"
                              className="login-error"
                           />
                           <Field
                              type="select"
                              name="end-year"
                              className="alert-field"
                              placeholder="Ano Final"
                              min="1900"
                              max="2021"
                              value={maxYear}
                              onChange={e => setMaxYear(e.target.value)}
                           />
                           <ErrorMessage
                              component="span"
                              name="password"
                              className="login-error"
                            />
                        </div>

                        <div className="alert-form-two">
                           <Field
                              type="number"
                              name="distance"
                              className="alert-field"
                              placeholder="KM Máxima"
                           />
                           <ErrorMessage
                              component="span"
                              name="password"
                              className="login-error"
                           />
                           <Field
                              type="number"
                              name="distance"
                              className="alert-field"
                              placeholder="Distância"
                              value={maxDistance}
                              onChange={e => setMaxDistance(e.target.value)}
                           />
                           <ErrorMessage
                              component="span"
                              name="password"
                              className="login-error"
                            />
                        </div>
                        
                        {/* <button className="alert-btn" type="submit" onClick={handleSubmit}>Cadastrar</button> */}
                        
                        {/* {
                           id ? <button className="alert-btn" type="submit" onClick={handleSubmit}>Cadastrar</button>
                           : <button className="alert-btn" type="submit" onClick={handleSubmit}>Ajustar</button>
                        } */}

                        {/* {
                           id ? <button className="alert-btn" type="submit" onClick={handleSubmit}>Ajustar</button>
                           : <button className="alert-btn" type="submit" onClick={handleSubmit}>Cadastrar</button>
                        } */}

                        {verificationTypeBtn()}

                  </Form>
               </Formik>
            </div>
      <Footer />    
      </>
   )
}

export default AlertRegister;