import React, {useState} from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ErrorMessage, Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import '../register/Register.css'
import api from '../../services/api'

const Register = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    // const [complement, setComplement] = useState('');
    
    async function handleSubmit(){
        const data = {
            email: email,
            password: password,
            name: name,
            address: {
                zipCode: zipCode,
                street: street,
                number: number,
                state: state,
                city: city,
                neighborhood: neighborhood,
                complement: "Teste",
                country: "Brazil"
            }
        }

        if(name !== '' && password !== '' && email !== ''){

            const response = await api.post('/users', data);

            if(response.status === 200){
                window.location.href='/login'
            }else{
                alert('Erro ao cadastrar o usuário!');
            }
            
        }else{
            alert('Por favor, preencha todos os dados!');
        }
    }

    const validations = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
        name: yup.string().required(),
        zipCode: yup.number().required(),
        street: yup.string().required(),
        number: yup.number().required().positive().integer(),
        state: yup.string().required(),
        city: yup.string().required(),
        neighborhood: yup.string().required()
    })
    return (
        <>
        <Header />
        <div className="register-container">
            <Formik
                initialValues={{}}
                onSubmit={handleSubmit}
                validationSchema={validations}
            >
                <Form className="register">
                
                <h1>Registrar</h1>

                    <div className="register-group">
                        <Field
                            name="name"
                            className="register-field-one"
                            placeholder="Nome"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="name"
                            className="register-error"
                        /> */}
                    </div>
                    <div className="register-group">
                        <Field
                            name="email"
                            className="register-field-one"
                            placeholder="E-mail"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="email"
                            className="register-error"
                        /> */}
                    </div>
                    <div className="register-group">
                        <Field
                            type="password"
                            name="password"
                            className="register-field-one"
                            placeholder="Senha"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="password"
                            className="register-error"
                        /> */}
                    </div>
                    <div className="register-group">
                        <Field
                            name="state"
                            className="register-field"
                            placeholder="Estado"
                            value={state}
                            onChange={e => setState(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="state"
                            className="register-error"
                        /> */}
                    {/* </div>
                    <div className="register-group"> */}
                        <Field
                            name="zipCode"
                            className="register-field"
                            placeholder="CEP"
                            value={zipCode}
                            onChange={e => setZipCode(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="zipCode"
                            className="register-error"
                        /> */}
                    </div>
                    <div className="register-group">
                        <Field
                            name="city"
                            className="register-field"
                            placeholder="Cidade"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="city"
                            className="register-error"
                        /> */}
                    {/* </div>
                    <div className="register-group"> */}
                        <Field
                            name="neighborhood"
                            className="register-field"
                            placeholder="Bairro"
                            value={neighborhood}
                            onChange={e => setNeighborhood(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="neighborhood"
                            className="register-error"
                        /> */}
                    </div>
                    <div className="register-group">
                        <Field
                            name="street"
                            className="register-field"
                            placeholder="Rua"
                            value={street}
                            onChange={e => setStreet(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="street"
                            className="register-error"
                        /> */}
                    {/* </div> */}
                    {/* <div className="register-group"> */}
                        <Field
                            name="number"
                            className="register-field"
                            placeholder="Número"
                            value={number}
                            onChange={e => setNumber(e.target.value)}
                        />
                        {/* <ErrorMessage
                            component="span"
                            name="number"
                            className="register-error"
                        /> */}
                    </div>
                    <button className="register-btn" type="submit" onClick={handleSubmit}>Registrar</button>
                </Form>
                
            </Formik>
        </div>
        <Footer />
        </>
    )
}

export default Register