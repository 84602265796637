import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from '../pages/home';
import Login from '../pages/login';
import Register from '../pages/register';
import AlertRegister from '../pages/alertregister';
import Alert from '../pages/alert';
import Contact from '../pages/contact';
import Services from '../pages/services';
import Client from '../pages/client';
import About from '../pages/about';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';

import {history} from '../history';

const Routes = () => (

    <Router history={history}>
        <Switch>
             <Route path='/' exact component= {Home} />
             <Route path='/login' exact component= {Login} />
             <Route path='/register' exact component= {Register} />
             <Route path='/alertregister/:id?' exact component= {AlertRegister} />
             <Route path='/alert' exact component= {Alert} />
             <Route path='/contact' exact component= {Contact} />
             <Route path='/services' exact component= {Services} />
             <Route path='/client' exact component= {Client} />
             <Route path='/about' exact component= {About} />
             <PrivateRoute component={NotFound}/> 
        </Switch>
    </Router>
)

export default Routes